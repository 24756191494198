<template>
  <div>
    <el-card v-loading="loading" shadow="hover">
      <div slot="header">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input
              v-model="username"
              placeholder="请输入用户名称"
              @input="onInput"
            ></el-input>
          </el-col>
          <el-col :span="12" class="vv-text_r">
            <el-button
              v-if="userInfo['role'] === '超级管理员'"
              size="small"
              type="primary"
              icon="el-icon-s-tools"
              @click="onScan"
              >扫码设置</el-button
            >
            <el-popconfirm :title="`确定要清除学生管理的全部数据吗？`" v-if="userInfo['role'] === '超级管理员'" @confirm="onDel" style="margin: 0 10px">
              <el-button
                slot="reference"
                size="small"
                type="danger"
                icon="el-icon-delete-solid"
                >清除数据</el-button
              >
            </el-popconfirm>
            <el-button
              v-if="userInfo['role'] === '超级管理员'"
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="onAdd"
              >新建用户</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="username" label="用户名" width="200">
        </el-table-column>
        <el-table-column prop="role" label="角色" width="200">
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag
              size="mini"
              effect="dark"
              :type="statusType[scope.row.status - 1]"
            >
              {{ statusVal[scope.row.status - 1] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
          header-align="left"
          align="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="userInfo['role'] === '超级管理员'"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="onEdit(scope.row.id)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="onCurrentChange"
        hide-on-single-page
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-drawer
      :title="title"
      size="25%"
      :visible.sync="drawer"
      :wrapperClosable="false"
      :before-close="onClose"
    >
      <user-form
        ref="userForm"
        :id="id"
        :show="drawer"
        :type="type"
        @close="formClose"
        v-if="type !== 'scan'"
      />
      <user-scan 
        ref="userScan"
        :show="drawer"
        :type="type"
        @close="formClose"
        v-if="type === 'scan'"
      />
    </el-drawer>
  </div>
</template>

<script>
import { getUser, clearAllData } from "@/api/user";
import { getUserInfo } from "@/utils/auth";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Table,
  TableColumn,
  Pagination,
  Popover,
  Popconfirm,
  Tag,
  Drawer,
  Message
} from "element-ui";
import UserForm from "./form";
import UserScan from "./form/scan";
export default {
  name: "User",
  components: {
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Input.name]: Input,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Popover.name]: Popover,
    [Popconfirm.name]: Popconfirm,
    [Tag.name]: Tag,
    [Drawer.name]: Drawer,
    UserForm,
    UserScan
  },
  data() {
    return {
      loading: false,
      statusType: ['success','danger'],
      statusVal: ['正常','禁用'],
      username: null,
      tableData: [],
      pagesize: 10,
      total: 0,
      currentPage: 1,
      title: null,
      drawer: false,
      type: null,
      id: 0,
    };
  },
  computed: {
    userInfo() {
      return getUserInfo()
    }
  },
  methods: {
    onInput() {
      this.fetchData();
    },
    onDel() {
      clearAllData().then(() => {
        Message({
          type: "success",
          message: "数据清除成功",
        });
      })
    },
    onScan() {
      this.drawer = true;
      this.title = "扫码设置";
      this.type = "scan";
    },
    onAdd() {
      this.drawer = true;
      this.title = "新建用户";
      this.type = "add";
    },
    onEdit(id) {
      this.id = id;
      this.drawer = true;
      this.title = "编辑用户";
      this.type = "edit";
    },
    onCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      getUser({
        page_id: this.currentPage,
        page_size: this.pagesize,
        username: this.username,
      })
        .then((rs) => {
          this.tableData = rs.data.data;
          this.total = rs.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClose(done) {
      if (this.type === 'scan') {
        this.$refs["userScan"].clearFun();
      } else {
        this.$refs["userForm"].clearFun();
      }
      done();
    },
    formClose(status) {
      if (this.type === 'scan') {
        this.$refs["userScan"].clearFun();
      } else {
        this.$refs["userForm"].clearFun();
      }
      this.drawer = status;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
