<template>
  <div class="vv-drawer__content">
    <el-form
      :model="user"
      status-icon
      :rules="userRules"
      ref="userScan"
      label-width="120px"
    >
      <el-form-item
        label="每日扫码次数"
        prop="limit"
      >
        <el-input
          type="text"
          v-model="user.limit"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="vv-text_r">
      <el-button :loading="loading" type="primary" @click="onSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { getScanLimit, setScanLimit } from "@/api/user";
import {
  Form,
  FormItem,
  Input,
  Button,
  Message,
} from "element-ui";
export default {
  name: "userScan",
  props: {
    type: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  data() {
    return {
      user: {
        limit: null
      },
      userRules: {
        limit: [
          { required: true, message: "请输入每日扫码次数", trigger: "blur" },
        ]
      },
      loading: false,
    };
  },
  methods: {
    onRead() {
      getScanLimit().then((rs) => {
        this.user = {
          limit: rs.data.data
        };
      });
    },
    clearFun() {
      this.$refs["userScan"].clearValidate();
      this.user = {
        limit: null,
      };
    },
    onSubmit() {
      this.$refs["userScan"]["validate"]((valid) => {
        if (valid) {
          this.loading = true;
          setScanLimit({
            limit: parseInt(this.user.limit)
          })
            .then(() => {
              Message({
                type: "success",
                message: "修改每日扫码次数成功",
              });
              this.$emit("close", false);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.onRead();
        }
      },
      immediate: true,
    },
  },
};
</script>
