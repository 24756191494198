<template>
  <div class="vv-drawer__content">
    <el-form
      :model="user"
      status-icon
      :rules="userRules"
      ref="userForm"
      label-width="80px"
    >
      <el-form-item
        label="用户名"
        :prop="type === 'add' ? 'username' : undefined"
      >
        <el-input
          type="text"
          v-model="user.username"
          :readonly="type === 'edit'"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="user.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="againPassword">
        <el-input type="password" v-model="user.againPassword"></el-input>
      </el-form-item>
    </el-form>
    <div class="vv-text_r">
      <el-button :loading="loading" type="primary" @click="onSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { addUser, readUser, editUser } from "@/api/user";
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Button,
  Message,
} from "element-ui";
export default {
  name: "userForm",
  props: {
    type: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("请输入6位以上密码"));
      } else {
        callback();
      }
    };
    let validateAgainPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.user.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      user: {
        username: null,
        password: null,
        againPassword: null,
      },
      userRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, validator: validatePass, trigger: "blur" }],
        againPassword: [
          { required: true, validator: validateAgainPass, trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    onRead() {
      readUser(this.id).then((rs) => {
        this.user = {
          username: rs.data.data["username"],
        };
      });
    },
    clearFun() {
      this.$refs["userForm"].clearValidate();
      this.user = {
        username: null,
      };
    },
    onSubmit() {
      this.$refs["userForm"]["validate"]((valid) => {
        if (valid) {
          this.loading = true;
          switch (this.type) {
            case "add":
              addUser({
                username: this.user.username,
                password: this.user.password,
              })
                .then(() => {
                  Message({
                    type: "success",
                    message: "用户新建成功",
                  });
                  this.$emit("close", false);
                })
                .finally(() => {
                  this.loading = false;
                });
              break;
            case "edit":
              editUser(this.id, {
                password: this.user.password,
              })
                .then(() => {
                  Message({
                    type: "success",
                    message: "用户修改成功",
                  });
                  this.$emit("close", false);
                })
                .finally(() => {
                  this.loading = false;
                });
              break;
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.type === "edit") {
            this.onRead();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
